import { defineNuxtPlugin } from '#app'
import { createVuetify } from 'vuetify'

const isDev = process.env.NODE_ENV === "development"
const options = JSON.parse('{"ssr":true,"treeshaking":true,"useVuetifyLabs":false,"icons":{"defaultSet":"mdi","aliases":{"collapse":"mdi-chevron-up","complete":"mdi-check","cancel":"mdi-close-circle","close":"mdi-close","delete":"mdi-close-circle","clear":"mdi-close-circle","success":"mdi-check-circle","info":"mdi-information","warning":"mdi-alert-circle","error":"mdi-close-circle","prev":"mdi-chevron-left","next":"mdi-chevron-right","checkboxOn":"mdi-checkbox-marked","checkboxOff":"mdi-checkbox-blank-outline","checkboxIndeterminate":"mdi-minus-box","delimiter":"mdi-circle","sortAsc":"mdi-arrow-up","sortDesc":"mdi-arrow-down","expand":"mdi-chevron-down","menu":"mdi-menu","subgroup":"mdi-menu-down","dropdown":"mdi-menu-down","radioOn":"mdi-radiobox-marked","radioOff":"mdi-radiobox-blank","edit":"mdi-pencil","ratingEmpty":"mdi-star-outline","ratingFull":"mdi-star","ratingHalf":"mdi-star-half-full","loading":"mdi-cached","first":"mdi-page-first","last":"mdi-page-last","unfold":"mdi-unfold-more-horizontal","file":"mdi-paperclip","plus":"mdi-plus","minus":"mdi-minus","calendar":"mdi-calendar","treeviewCollapse":"mdi-menu-down","treeviewExpand":"mdi-menu-right","eyeDropper":"mdi-eyedropper"},"sets":{"mdi":{}}},"theme":{"defaultTheme":"myCustomLightTheme","themes":{"myCustomLightTheme":{"dark":false,"colors":{"main":"#25B6BF","secondary":"#FFB800","error":"#EE2828","theme-green":"#28C76F","theme-eastern-blue":"#179CA5","theme-light-blue":"#CDF3F5","theme-black":"#1D1D1D","theme-white":"#FFFFFF","theme-dark-gray":"#989898","theme-gray":"#D6D6D6","theme-light-gray":"#ECECEC"}}}},"display":{"mobileBreakpoint":"md"}}')

''

export default defineNuxtPlugin(nuxtApp => {
  const vuetify = createVuetify(options)
  nuxtApp.vueApp.use(vuetify)

  if (!process.server && isDev) {
    // eslint-disable-next-line no-console
    console.log('💚 Initialized Vuetify 3', vuetify)
  }

  return {
    provide: {
      vuetify
    }
  }
})
