import 'vue3-toastify/dist/index.css'

import Vue3Toasity, { type ToastContainerOptions } from 'vue3-toastify'

import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(Vue3Toasity, {
    autoClose: 3000,
  } as ToastContainerOptions)
})
