import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('page:finish', () => {
    setTimeout(() => {
      const el = document.getElementById('start')
      el?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }, 300)
  })
})
