import type { RouterConfig } from '@nuxt/schema'

export default <RouterConfig>{
  scrollBehavior: (to, from, savedPosition) => {
    if (to !== from) {
      return {
        left: 0,
        top: 0,
        behavior: 'smooth',
      }
    }
  },
}
