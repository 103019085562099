export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, maximum-scale=1, initial-scale=1, shrink-to-fit=no, user-scalable=no"},{"charset":"utf-8"}],"link":[{"rel":"icon","href":"/img.png"},{"rel":"stylesheet","type":"text/css","href":"https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css"}],"style":[],"script":[{"src":"https://api-maps.yandex.ru/2.1/?apikey=97650fc1-1923-4f9b-ac19-291883495139&suggest_apikey=ad1fb5a0-b5f1-4b60-bcda-eb53a37775a5&lang=ru_RU","type":"text/javascript"}],"noscript":[],"htmlAttrs":{"lang":"ru"},"charset":"utf-8","viewport":"width=device-width, maximum-scale=1, initial-scale=1, shrink-to-fit=no, user-scalable=no"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"