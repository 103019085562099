
// Generated by dayjs-nuxt-module
import dayjs from 'dayjs'

import updateLocale from 'dayjs/plugin/updateLocale'
import utc from 'dayjs/plugin/utc'

dayjs.extend(updateLocale)
dayjs.extend(utc)






export default dayjs
