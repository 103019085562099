import { default as indexM1kaIbmdBWMeta } from "/var/www/taptatti/nuxt/pages/index.vue?macro=true";
import { default as indexvyU0LSuvegMeta } from "/var/www/taptatti/nuxt/pages/[city]/about/index.vue?macro=true";
import { default as indexCW74l4aGJcMeta } from "/var/www/taptatti/nuxt/pages/[city]/basket/index.vue?macro=true";
import { default as indexMQdeOm5BGyMeta } from "/var/www/taptatti/nuxt/pages/[city]/bonus/index.vue?macro=true";
import { default as indexTTH0frClOCMeta } from "/var/www/taptatti/nuxt/pages/[city]/cabinet/index.vue?macro=true";
import { default as indexJKXh85kDRgMeta } from "/var/www/taptatti/nuxt/pages/[city]/carrier/index.vue?macro=true";
import { default as AddressItemXarVvw8KPAMeta } from "/var/www/taptatti/nuxt/pages/[city]/contacts/components/AddressItem.vue?macro=true";
import { default as AlmatyYcjqkNTXhGMeta } from "/var/www/taptatti/nuxt/pages/[city]/contacts/components/Almaty.vue?macro=true";
import { default as AqtobeoXOz8yWTVeMeta } from "/var/www/taptatti/nuxt/pages/[city]/contacts/components/Aqtobe.vue?macro=true";
import { default as AstanaVJdPqy18mNMeta } from "/var/www/taptatti/nuxt/pages/[city]/contacts/components/Astana.vue?macro=true";
import { default as AtyrauGUBuhpaK6aMeta } from "/var/www/taptatti/nuxt/pages/[city]/contacts/components/Atyrau.vue?macro=true";
import { default as CityContacts0TaO5Mu4vcMeta } from "/var/www/taptatti/nuxt/pages/[city]/contacts/components/CityContacts.vue?macro=true";
import { default as KokshetauaqVjPQccJoMeta } from "/var/www/taptatti/nuxt/pages/[city]/contacts/components/Kokshetau.vue?macro=true";
import { default as PavlodarGzWYrcWOdHMeta } from "/var/www/taptatti/nuxt/pages/[city]/contacts/components/Pavlodar.vue?macro=true";
import { default as Qaragandy7dcm0AonUNMeta } from "/var/www/taptatti/nuxt/pages/[city]/contacts/components/Qaragandy.vue?macro=true";
import { default as ShymkentV6ftvydoiUMeta } from "/var/www/taptatti/nuxt/pages/[city]/contacts/components/Shymkent.vue?macro=true";
import { default as indexHc2K4mI91MMeta } from "/var/www/taptatti/nuxt/pages/[city]/contacts/index.vue?macro=true";
import { default as index5Egq0FD6JoMeta } from "/var/www/taptatti/nuxt/pages/[city]/franchise/index.vue?macro=true";
import { default as index8rLwFPOxcKMeta } from "/var/www/taptatti/nuxt/pages/[city]/franchising/index.vue?macro=true";
import { default as indexUs5zFjUdqRMeta } from "/var/www/taptatti/nuxt/pages/[city]/gift/index.vue?macro=true";
import { default as indexwQmsdFWxjQMeta } from "/var/www/taptatti/nuxt/pages/[city]/index.vue?macro=true";
import { default as confirmation1SGLE5sE8fMeta } from "/var/www/taptatti/nuxt/pages/[city]/order/confirmation.vue?macro=true";
import { default as indexxIBfySqcgXMeta } from "/var/www/taptatti/nuxt/pages/[city]/order/index.vue?macro=true";
import { default as indexO2rLwjo9S8Meta } from "/var/www/taptatti/nuxt/pages/[city]/policy/index.vue?macro=true";
import { default as _91id_93RLwBH2S28pMeta } from "/var/www/taptatti/nuxt/pages/[city]/product/[id].vue?macro=true";
import { default as indexC6XKvrlw74Meta } from "/var/www/taptatti/nuxt/pages/[city]/rent/index.vue?macro=true";
import { default as indexYVgBKpGGkcMeta } from "/var/www/taptatti/nuxt/pages/[city]/rental-of-premises/index.vue?macro=true";
import { default as indextUXeiqd5QNMeta } from "/var/www/taptatti/nuxt/pages/[city]/test/index.vue?macro=true";
import { default as index1QOEgbqN1uMeta } from "/var/www/taptatti/nuxt/pages/[city]/user-agreement/index.vue?macro=true";
import { default as component_45stubvzDAI6oh0FMeta } from "/var/www/taptatti/nuxt/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubvzDAI6oh0F } from "/var/www/taptatti/nuxt/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/taptatti/nuxt/pages/index.vue")
  },
  {
    name: "city-about___kz",
    path: "/kz/:city()/about",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/about/index.vue")
  },
  {
    name: "city-about___ru",
    path: "/ru/:city()/about",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/about/index.vue")
  },
  {
    name: "city-basket___kz",
    path: "/kz/:city()/basket",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/basket/index.vue")
  },
  {
    name: "city-basket___ru",
    path: "/ru/:city()/basket",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/basket/index.vue")
  },
  {
    name: "city-bonus___kz",
    path: "/kz/:city()/bonus",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/bonus/index.vue")
  },
  {
    name: "city-bonus___ru",
    path: "/ru/:city()/bonus",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/bonus/index.vue")
  },
  {
    name: "city-cabinet___kz",
    path: "/kz/:city()/cabinet",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/cabinet/index.vue")
  },
  {
    name: "city-cabinet___ru",
    path: "/ru/:city()/cabinet",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/cabinet/index.vue")
  },
  {
    name: "city-carrier___kz",
    path: "/kz/:city()/carrier",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/carrier/index.vue")
  },
  {
    name: "city-carrier___ru",
    path: "/ru/:city()/carrier",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/carrier/index.vue")
  },
  {
    name: "city-contacts-components-AddressItem___kz",
    path: "/kz/:city()/contacts/components/AddressItem",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/contacts/components/AddressItem.vue")
  },
  {
    name: "city-contacts-components-AddressItem___ru",
    path: "/ru/:city()/contacts/components/AddressItem",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/contacts/components/AddressItem.vue")
  },
  {
    name: "city-contacts-components-Almaty___kz",
    path: "/kz/:city()/contacts/components/Almaty",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/contacts/components/Almaty.vue")
  },
  {
    name: "city-contacts-components-Almaty___ru",
    path: "/ru/:city()/contacts/components/Almaty",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/contacts/components/Almaty.vue")
  },
  {
    name: "city-contacts-components-Aqtobe___kz",
    path: "/kz/:city()/contacts/components/Aqtobe",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/contacts/components/Aqtobe.vue")
  },
  {
    name: "city-contacts-components-Aqtobe___ru",
    path: "/ru/:city()/contacts/components/Aqtobe",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/contacts/components/Aqtobe.vue")
  },
  {
    name: "city-contacts-components-Astana___kz",
    path: "/kz/:city()/contacts/components/Astana",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/contacts/components/Astana.vue")
  },
  {
    name: "city-contacts-components-Astana___ru",
    path: "/ru/:city()/contacts/components/Astana",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/contacts/components/Astana.vue")
  },
  {
    name: "city-contacts-components-Atyrau___kz",
    path: "/kz/:city()/contacts/components/Atyrau",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/contacts/components/Atyrau.vue")
  },
  {
    name: "city-contacts-components-Atyrau___ru",
    path: "/ru/:city()/contacts/components/Atyrau",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/contacts/components/Atyrau.vue")
  },
  {
    name: "city-contacts-components-CityContacts___kz",
    path: "/kz/:city()/contacts/components/CityContacts",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/contacts/components/CityContacts.vue")
  },
  {
    name: "city-contacts-components-CityContacts___ru",
    path: "/ru/:city()/contacts/components/CityContacts",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/contacts/components/CityContacts.vue")
  },
  {
    name: "city-contacts-components-Kokshetau___kz",
    path: "/kz/:city()/contacts/components/Kokshetau",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/contacts/components/Kokshetau.vue")
  },
  {
    name: "city-contacts-components-Kokshetau___ru",
    path: "/ru/:city()/contacts/components/Kokshetau",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/contacts/components/Kokshetau.vue")
  },
  {
    name: "city-contacts-components-Pavlodar___kz",
    path: "/kz/:city()/contacts/components/Pavlodar",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/contacts/components/Pavlodar.vue")
  },
  {
    name: "city-contacts-components-Pavlodar___ru",
    path: "/ru/:city()/contacts/components/Pavlodar",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/contacts/components/Pavlodar.vue")
  },
  {
    name: "city-contacts-components-Qaragandy___kz",
    path: "/kz/:city()/contacts/components/Qaragandy",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/contacts/components/Qaragandy.vue")
  },
  {
    name: "city-contacts-components-Qaragandy___ru",
    path: "/ru/:city()/contacts/components/Qaragandy",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/contacts/components/Qaragandy.vue")
  },
  {
    name: "city-contacts-components-Shymkent___kz",
    path: "/kz/:city()/contacts/components/Shymkent",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/contacts/components/Shymkent.vue")
  },
  {
    name: "city-contacts-components-Shymkent___ru",
    path: "/ru/:city()/contacts/components/Shymkent",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/contacts/components/Shymkent.vue")
  },
  {
    name: "city-contacts___kz",
    path: "/kz/:city()/contacts",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/contacts/index.vue")
  },
  {
    name: "city-contacts___ru",
    path: "/ru/:city()/contacts",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/contacts/index.vue")
  },
  {
    name: "city-franchise___kz",
    path: "/kz/:city()/franchise",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/franchise/index.vue")
  },
  {
    name: "city-franchise___ru",
    path: "/ru/:city()/franchise",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/franchise/index.vue")
  },
  {
    name: "city-franchising___kz",
    path: "/kz/:city()/franchising",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/franchising/index.vue")
  },
  {
    name: "city-franchising___ru",
    path: "/ru/:city()/franchising",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/franchising/index.vue")
  },
  {
    name: "city-gift___kz",
    path: "/kz/:city()/gift",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/gift/index.vue")
  },
  {
    name: "city-gift___ru",
    path: "/ru/:city()/gift",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/gift/index.vue")
  },
  {
    name: "city___kz",
    path: "/kz/:city()",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/index.vue")
  },
  {
    name: "city___ru",
    path: "/ru/:city()",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/index.vue")
  },
  {
    name: "city-order-confirmation___kz",
    path: "/kz/:city()/order/confirmation",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/order/confirmation.vue")
  },
  {
    name: "city-order-confirmation___ru",
    path: "/ru/:city()/order/confirmation",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/order/confirmation.vue")
  },
  {
    name: "city-order___kz",
    path: "/kz/:city()/order",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/order/index.vue")
  },
  {
    name: "city-order___ru",
    path: "/ru/:city()/order",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/order/index.vue")
  },
  {
    name: "city-policy___kz",
    path: "/kz/:city()/policy",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/policy/index.vue")
  },
  {
    name: "city-policy___ru",
    path: "/ru/:city()/policy",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/policy/index.vue")
  },
  {
    name: "city-product-id___kz",
    path: "/kz/:city()/product/:id()",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/product/[id].vue")
  },
  {
    name: "city-product-id___ru",
    path: "/ru/:city()/product/:id()",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/product/[id].vue")
  },
  {
    name: "city-rent___kz",
    path: "/kz/:city()/rent",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/rent/index.vue")
  },
  {
    name: "city-rent___ru",
    path: "/ru/:city()/rent",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/rent/index.vue")
  },
  {
    name: "city-rental-of-premises___kz",
    path: "/kz/:city()/rental-of-premises",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/rental-of-premises/index.vue")
  },
  {
    name: "city-rental-of-premises___ru",
    path: "/ru/:city()/rental-of-premises",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/rental-of-premises/index.vue")
  },
  {
    name: "city-test___kz",
    path: "/kz/:city()/test",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/test/index.vue")
  },
  {
    name: "city-test___ru",
    path: "/ru/:city()/test",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/test/index.vue")
  },
  {
    name: "city-user-agreement___kz",
    path: "/kz/:city()/user-agreement",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/user-agreement/index.vue")
  },
  {
    name: "city-user-agreement___ru",
    path: "/ru/:city()/user-agreement",
    component: () => import("/var/www/taptatti/nuxt/pages/[city]/user-agreement/index.vue")
  },
  {
    name: "index___kz",
    path: "/kz",
    component: () => import("/var/www/taptatti/nuxt/pages/index.vue")
  },
  {
    name: "index___ru",
    path: "/ru",
    component: () => import("/var/www/taptatti/nuxt/pages/index.vue")
  },
  {
    name: component_45stubvzDAI6oh0FMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubvzDAI6oh0F
  }
]